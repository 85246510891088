import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../../core/config/atoms';

import Skeleton from 'react-loading-skeleton';

import { motion } from "framer-motion";

import ServiceOverview from '../../../../services/serviceOverview';

export default function ComponentCustomerCountCard() {

    const [token] = useRecoilState(tokenAtom);

    const [counts, setCounts] = useState(0);

    const [loading, setLoading] = useState(true);

    const overviewService = new ServiceOverview();

    const loadData = () => {
        (async function () {
            const res = await overviewService.getCustomerCount({}, token);
            setCounts(res.data);
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <motion.div whileHover={{ scale: 1.05 }} className="col-span-2 cursor-pointer">
            {
                !loading && <div className="shadow bg-yellow-200 rounded-lg">
                    <div className="box p-5">
                        <div className="flex items-center">
                            <i className="las la-user text-4xl 2xl:text-6xl text-yellow-500"></i>
                            <div className="ml-auto text-right">
                                <div className="text-2xl font-bold leading-8">{counts}</div>
                                <div className="text-xs text-gray-600 mt-1">Total Customers</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {loading && <Skeleton className='h-[100px]' />}
        </motion.div>
    )
}
