import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceFeedback {
    lookupService = new APIServiceLookUp();

    async getAllFeedback(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}feedback/getAllFeedback`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateFeedback(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}feedback/updateFeedback`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}