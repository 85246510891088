import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../core/config/atoms';

import { Edit } from 'lucide-react';

import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import { Dialog } from '@headlessui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import PanelDev from '../../dev/PanelDev';

import animPage from '../../../assets/anim/anim-pageload.json';
import animBtnLoading from '../../../assets/anim/anim-btnLoading.json';

import ServiceProfiles from 'services/serviceProfiles';
import ServiceUtility from 'services/serviceUtility';
import ServiceTransaction from 'services/serviceTransaction';

import PaneTicketCustomer from '../panes/PaneTicketCustomer';
import PaneTransaction from '../panes/PaneTransaction';
import PaneReviewsCustomer from '../panes/PaneReviewsCustomer';
import ServiceOption from 'services/serviceOption';

export default function ComponentCustomerInfo({ cid }) {
    const [token] = useRecoilState(tokenAtom);

    const [contryOptions, setCountryOptions] = useState([]);

    const [user, setUser] = useState({});
    const [wallet, setWallet] = useState({});

    const [loading, setLoading] = useState(true);
    const [submit, setSubmit] = useState(false);
    const [editA, setEditA] = useState(false);
    const [editB, setEditB] = useState(false);

    const [tab, setTab] = useState('ticket');
    const [tabA, setTabA] = useState('a');

    const profileService = new ServiceProfiles();
    const utilityService = new ServiceUtility();
    const transactionService = new ServiceTransaction();
    const optionService = new ServiceOption();

    const formVSchemaA = Yup.object().shape({
        country: Yup.string().required('This information is required'),
    });

    const formVSchemaB = Yup.object().shape({
        phone: Yup.string().required('This information is required').matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
    });

    const getInitValues = () => {
        if (editB) {
            return { country: user.country };
        }
        if (editA) {
            return { phone: user.user.phone };
        }
        return { country: user.country };;
    }

    const getInitSchema = () => {
        if (editB) {
            return formVSchemaA;
        }
        if (editA) {
            return formVSchemaB;
        }
        return formVSchemaA;
    }

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: getInitValues(),
        validationSchema: getInitSchema(),
        enableReinitialize: true,
        onSubmit: values => {
            setSubmit(true);
            if (editB) {
                let body = {
                    uid: user._id,
                    data: {
                        "country": values.country
                    },
                    idpdata: {}
                };
                profileService.userUpdate(body, token).then((res) => {
                    if (res.status) {
                        resetForm();
                        setEditB(false);
                        toast.success('User country updated.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        loadData();
                    } else {
                        toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                    setSubmit(false);
                });
            }
            if (editA) {
                let body = {
                    uid: user._id,
                    data: {},
                    idpdata: {
                        "phone": values.phone
                    }
                };
                profileService.userUpdate(body, token).then((res) => {
                    if (res.status) {
                        resetForm();
                        setEditA(false);
                        toast.success('User phone number updated.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        loadData();
                    } else {
                        toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                    setSubmit(false);
                });
            }
        }
    });

    const formatMinutesToTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const remainingSeconds = seconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);
        const finalSeconds = remainingSeconds % 60;
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedFinalSeconds = String(finalSeconds).padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}:${formattedFinalSeconds}`;
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            var cuRes = await optionService.getCountryOptions({}, '');
            setCountryOptions(cuRes.data);

            let body = {
                'cid': cid
            };
            var res = await profileService.getCustomerById(body, token);
            if (res['status']) {
                setUser(res.data);
                var resW = await transactionService.getWalletById({ 'wKey': res.data.walletKey }, token);
                if (resW.data !== null) {
                    setWallet(resW['data']);
                } else {
                    setWallet({});
                }
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, [cid]);

    return (
        <div>
            {
                loading && <div className='flex items-center justify-center w-full h-screen'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <Dialog open={editA} onClose={() => setEditA(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Update Phone Number &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setEditA(false);
                            handleReset();
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className="mt-4" onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Enter mobile number <sup className="text-red-600">*</sup></label>
                            <input type="text" placeholder="i.e. 123456789" onChange={handleChange} value={values.phone} id="phone" className="w-full h-11 rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1" autoComplete="off" />
                            {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                        </div>
                        <div className='flex items-end'>
                            <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && "UPDATE"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            <Dialog open={editB} onClose={() => setEditB(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Update Country &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setEditB(false);
                            handleReset();
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Enter country <sup className="text-red-600">*</sup></label>
                            <select id="country" onChange={handleChange} value={values.country} className="appearance-none w-full rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1">
                                <option value="" disabled="" selected="selected">Select</option>
                                {
                                    contryOptions.length !== 0 && contryOptions.map((item) => {
                                        return <option value={item.value}>{item.label}</option>
                                    })
                                }
                            </select>
                            {(errors.country && touched.country) && <p className='text-xs text-red-400 mt-1'>{errors.country}</p>}
                        </div>
                        <div className='flex items-end'>
                            <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && "UPDATE"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            {
                !loading && <div>
                    <div className='h-[320px] bg-blue-50 px-4 relative'>
                        <div className='h-[220px] bg-blue-600 rounded-b-md'></div>
                        <div className='w-[180px] h-[180px] bg-white border-[6px] border-blue-50 rounded-full absolute top-[120px] ml-8'>
                            <img src={utilityService.getIsObjectEmpty(user.profileMedia) ? user.gender === "Male" ? "https://storage.googleapis.com/ish-prod/ish-assets/male.png" : "https://storage.googleapis.com/ish-prod/ish-assets/female.png" : user.profileMedia.fileurl} alt='Profile Image' className='w-full h-full rounded-full' />
                        </div>
                        <h1 className='ml-[220px] text-4xl font-medium absolute'>{utilityService.getFullName(user["user"])}</h1>
                    </div>
                    <div className='bg-gray-50 px-12 py-4'>
                        <div className='bg-white w-full shadow rounded-md p-4 flex'>
                            <div className='w-[30%]'>
                                <h2 className='text-xl font-medium'>About</h2>
                                <ul className='mt-2 space-y-3'>
                                    <li className={`text-sm font-medium py-1 px-2 rounded-md ${tabA === "a" ? "text-blue-600 bg-blue-50 hover:bg-blue-50" : "bg-gray-50 hover:bg-blue-100"} cursor-pointer`} onClick={() => { setTabA('a') }}>Customer Information</li>
                                    <li className={`text-sm font-medium py-1 px-2 rounded-md ${tabA === "b" ? "text-blue-600 bg-blue-50 hover:bg-blue-50" : "bg-gray-50 hover:bg-blue-100"} cursor-pointer`} onClick={() => { setTabA('b') }}>Wallet Balence</li>
                                </ul>
                            </div>
                            <div className='w-[1px] bg-gray-200 mx-2'></div>
                            {
                                (() => {
                                    switch (tabA) {
                                        case 'a':
                                            return <div className='flex-grow'>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">Email Address</div>
                                                    <div className="text-slate-950 text-sm font-medium">{user.user.email}</div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal flex">Phone Number <span><Edit size={14} className='text-blue-600 ml-1 cursor-pointer' onClick={() => { setEditA(true) }} /></span></div>
                                                    <div className="text-slate-950 text-sm font-medium">{user.user.phone === "" ? "-" : `+91 ${user.user.phone}`}</div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">Gender</div>
                                                    <div className="text-slate-950 text-sm font-medium">{user.gender}</div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">Condition</div>
                                                    <div className="text-slate-950 text-sm font-medium">{user.disbilities}</div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal flex">Country <span><Edit size={14} className='text-blue-600 ml-1 cursor-pointer' onClick={() => { setEditB(true) }} /></span></div>
                                                    <div className="text-slate-950 text-sm font-medium">{user.country}</div>
                                                </div>
                                                <div className='p-2'>
                                                    <div className="text-slate-400 text-xs font-normal">Language Preference</div>
                                                    <div className='text-sm text-black mt-2 flex space-x-2'>
                                                        {
                                                            user.langs.map((lang) => {
                                                                return <div className='bg-prime text-white px-4 rounded-full'>{lang}</div>
                                                            })
                                                        }
                                                        {
                                                            user.langs.length === 0 && <div>NA</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>;
                                        case 'b':
                                            return <div className='flex-grow'>
                                                <div>
                                                    <h1 className='text-xl text-prime font-bold'>Wallet balance</h1>
                                                    <p className='text-base'>Total wallet balance is : {Object.keys(wallet).length !== 0 ? formatMinutesToTime(wallet.balence) : "00:00"} Min</p>
                                                </div>
                                                <ul className='space-y-4 mt-4'>
                                                    {
                                                        Object.keys(wallet).length !== 0 && wallet.packBalence.map((pack) => {
                                                            return <li className='w-full flex items-center justify-between'>
                                                                <div>
                                                                    <h1 className='text-base font-bold'>{pack.title}</h1>
                                                                    <h2 className='text-sm text-prime font-medium'>Current Balance : {formatMinutesToTime(pack.value)} Min</h2>
                                                                </div>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div>;
                                        default:
                                            return (<PanelDev />);
                                    }
                                })()
                            }
                        </div>
                        <div className='bg-white w-full shadow rounded-md p-4 mt-4'>
                            <h2 className='text-xl font-medium'>Activity</h2>
                            <div class="w-full mt-2">
                                <div className="py-2 mt-2">
                                    <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                        <li className="mx-2"><button className={`py-2 px-2 ${tab === 'ticket' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('ticket') }}>Ticket Report</button></li>
                                        <li className="mx-2"><button className={`py-2 px-2 ${tab === 'transaction' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('transaction') }}>Transaction Report</button></li>
                                        <li className="mx-2"><button className={`py-2 px-2 ${tab === 'reviews' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('reviews') }}>Reviews Report</button></li>
                                    </ul>
                                </div>
                            </div>
                            {
                                (() => {
                                    switch (tab) {
                                        case 'ticket':
                                            return (<PaneTicketCustomer cid={cid} />);
                                        case 'transaction':
                                            return (<PaneTransaction cid={user.userId} />);
                                        case 'reviews':
                                            return (<PaneReviewsCustomer cid={cid} />);
                                        default:
                                            return (<PanelDev />);
                                    }
                                })()
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
