import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from 'core/config/atoms';

import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import ServiceProfiles from 'services/serviceProfiles';
import ServiceUtility from 'services/serviceUtility';

export default function ComponentCompList() {
    const [token] = useRecoilState(tokenAtom);

    const [interpreters, setInterpreters] = useState([]);

    const [loading, setLoading] = useState(true);

    const profileService = new ServiceProfiles();
    const utilityService = new ServiceUtility();

    const loadData = () => {
        (async function () {
            var res = await profileService.getTopInterpreter({}, token);
            setLoading(false);
            if (res['status']) {
                setInterpreters(res.data.reverse());
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="col-span-4 cursor-pointer">
            <div className="shadow bg-white rounded-lg p-4">
                <div className='flex items-center justify-between'>
                    <h3 className='text-lg font-sans font-medium'>Top Performing Interpreters</h3>
                </div>
                <hr className='my-2' />
                {loading && <Skeleton count={10} />}
                {
                    (interpreters.length == 0 && !loading) && <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2'>
                        <p className='text-center'>No data found.</p>
                    </div>
                }
                <div className='space-y-2'>
                    {
                        interpreters.map((interpreter) => {
                            return <div className='w-full flex'>
                                <img src={utilityService.getIsObjectEmpty(interpreter.profileMedia) ? "https://storage.googleapis.com/ish-prod/ish-assets/default.jpeg" : interpreter.profileMedia.fileurl} alt='Profile Image' className='w-14 h-14 rounded mr-6' />
                                <div className='flex flex-col items-start justify-start ml-2'>
                                    <h1 className='text-sm font-medium text-black'>{interpreter.fname}{interpreter.mname === "NA" ? "" : ` ${interpreter.mname}`}{interpreter.lname === "NA" ? "" : ` ${interpreter.lname}`}</h1>
                                    <p className='text-xs font-medium text-gray-600'>Interpreter</p>
                                </div>
                                <div className='flex-grow'></div>
                                <div className='text-xs bg-yellow-200 text-yellow-600 h-[20px] px-2 rounded-full'>
                                    <p><i className="las la-star text-sm"></i> {interpreter.rating.toFixed(2)}</p>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div >
    )
}
