import React, { useState } from 'react';

import { ScrollArea } from "@/components/ui/scroll-area";

import TopBar from '../core/widgets/ui/TopBar';

import PanelDev from '../panels/dev/PanelDev';

import PanelReportTicket from '../panels/reports/PanelReportTicket';
import PanelReportTransactions from '../panels/reports/PanelReportTransactions';
import PanelReportFeedback from '../panels/reports/PanelReportFeedback';
import PanelReportLogs from '../panels/reports/PanelReportLogs';

export default function LayoutReports() {

    const [tab, setTab] = useState('reports');

    return (
        <ScrollArea className='h-screen w-full py-3 px-3'>
            <div className="grid grid-cols-12 grid-gap-2">
                <div className="col-span-12 mt-2">
                    <div class="intro-y flex items-center justify-between h-10">
                        <h2 class="text-2xl font-medium truncate ml-2">
                            Reports Master
                        </h2>
                        <div className='flex z-50'>
                            <TopBar />
                        </div>
                    </div>
                    <div class="w-full mt-2">
                        <div className="p-2 mt-2">
                            <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                <li className="mx-2"><button className={`py-2 px-2 ${tab === 'reports' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('reports') }}>Ticket Report</button></li>
                                <li className="mx-2"><button className={`py-2 px-2 ${tab === 'transactions' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('transactions') }}>Transactions Report</button></li>
                                <li className="mx-2"><button className={`py-2 px-2 ${tab === 'feedback' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('feedback') }}>Feedback Report</button></li>
                                <li className="mx-2"><button className={`py-2 px-2 ${tab === 'logs' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('logs') }}>Logs Report</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {
                (() => {
                    switch (tab) {
                        case 'reports':
                            return (<PanelReportTicket />);
                        case 'transactions':
                            return (<PanelReportTransactions />);
                        case 'feedback':
                            return (<PanelReportFeedback />);
                        case 'logs':
                            return (<PanelReportLogs />);
                        default:
                            return (<PanelDev />);
                    }
                })()
            }
        </ScrollArea>
    )
}
