import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../../core/config/atoms';

import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import { Label, Pie, PieChart } from "recharts";
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent, } from "@/components/ui/chart";

import ServiceOverview from 'services/serviceOverview';

export default function ComponentTechList() {

    const [token] = useRecoilState(tokenAtom);

    const [chartData, setChartData] = useState([]);

    const [total, setTotal] = useState(0);

    const [loading, setLoading] = useState(true);

    const overviewService = new ServiceOverview();

    const chartConfig = {
        visitors: {
            label: "Tickets Type",
            color: "hsl(173 58% 39%)",
        },
        chrome: {
            label: "partner",
            color: "hsl(173 58% 39%)",
        },
        safari: {
            label: "Safari",
            color: "hsl(var(--chart-2))",
        },
        firefox: {
            label: "Firefox",
            color: "hsl(var(--chart-3))",
        },
        edge: {
            label: "Edge",
            color: "hsl(var(--chart-4))",
        },
    };

    const loadData = () => {
        (async function () {
            var res = await overviewService.getChartData({}, token);
            setLoading(false);
            if (res['status']) {
                setChartData(res.data.data);
                setTotal(res.data.total)
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="col-span-4 cursor-pointer">
            <div className="shadow bg-white rounded-lg p-4">
                <div className='flex items-center justify-between'>
                    <h3 className='text-lg font-sans font-medium'>Split of Ticket Types</h3>
                </div>
                <hr className='my-2' />
                {loading && <Skeleton count={10} />}
                <div>
                    <ChartContainer config={chartConfig} className="mx-auto aspect-square max-h-[250px]">
                        <PieChart>
                            <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                            <Pie data={chartData} dataKey="visitors" nameKey="browser" innerRadius={60} strokeWidth={5}>
                                <Label content={({ viewBox }) => {
                                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                                        return (
                                            <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle" dominantBaseline="middle" >
                                                <tspan x={viewBox.cx} y={viewBox.cy} className="fill-foreground text-3xl font-bold">
                                                    {total}
                                                </tspan>
                                                <tspan x={viewBox.cx} y={(viewBox.cy || 0) + 24} className="fill-muted-foreground">
                                                    Tickets
                                                </tspan>
                                            </text>
                                        )
                                    }
                                }}
                                />
                            </Pie>
                        </PieChart>
                    </ChartContainer>
                </div>
                {
                    total === 0 && <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2'>
                        <p className='text-center'>No Data Found.</p>
                    </div>
                }
                {
                    total !== 0 && <div className=''>
                        <ul className='mx-20 space-y-2'>
                            <li className='flex items-center justify-between'>
                                <p className='font-medium'>Partner</p>
                                <p className='font-medium'>{chartData[0].visitors}</p>
                            </li>
                            <li className='flex items-center justify-between'>
                                <p className='font-medium'>Live-Interpret</p>
                                <p className='font-medium'>{chartData[1].visitors}</p>
                            </li>
                            <li className='flex items-center justify-between'>
                                <p className='font-medium'>Tele-Interpret</p>
                                <p className='font-medium'>{chartData[2].visitors}</p>
                            </li>
                            <li className='flex items-center justify-between'>
                                <p className='font-medium'>Helpdesk</p>
                                <p className='font-medium'>{chartData[3].visitors}</p>
                            </li>
                        </ul>
                    </div>
                }
            </div>
        </div>
    )
}
