import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { userAtom, tokenAtom } from 'core/config/atoms';

import { motion } from "framer-motion";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { toast } from 'react-toastify';
import Moment from 'moment';
import * as XLSX from 'xlsx';

import ServiceFeedback from 'services/serviceFeedback';

export default function PanelReportFeedback() {

    const [token] = useRecoilState(tokenAtom);

    const [feedbacks, setFeedbacks] = useState([]);

    const [filter, setFilter] = useState({});

    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const feedbackService = new ServiceFeedback();

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const getExportItems = () => {
        let items = [];
        feedbacks.forEach((item) => {
            items.push({
                name: `${item.fname}${item.mname == "NA" ? "" : ` ${item.mname}`}${item.lname == "NA" ? "" : ` ${item.lname}`}`,
                email: item.email,
                phone: item.phone,
                subject: item.subject,
                message: item.message,
                status: item.status,
                createdAt: item.createdAt,
            });
        });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "feedback");
        XLSX.writeFile(workbook, "feedbacks.xlsx");
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToCsv = () => {
        let headers = ['name,email,phone,subject,message,status,createdAt'];
        let usersCsv = feedbacks.reduce((tran, item) => {
            const { fname, mname, lname, email, phone, subject, message, status, createdAt } = item
            tran.push([`${fname}${mname == "NA" ? "" : ` ${mname}`}${lname == "NA" ? "" : ` ${lname}`}`, email, phone, subject, message, status, createdAt].join(','))
            return tran
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'feedback.csv',
            fileType: 'text/csv',
        });
    }

    const getStsCss = (status) => {
        switch (status) {
            case 'active':
                return " bg-yellow-100 text-yellow-800";
            case 'closed':
                return " bg-green-100 text-green-800";
            case 'cancel':
                return " bg-red-100 text-red-800";
            default:
                return " bg-gray-100 text-gray-800";
        }
    }

    const getStsTitle = (status) => {
        switch (status) {
            case 'active':
                return "Active";
            case 'closed':
                return "Closed";
            case 'cancel':
                return "Canceled";
            default:
                return "NA";
        }
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": "",
                "endDate": ""
            };
            var res = await feedbackService.getAllFeedback(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setFeedbacks(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": "",
                "endDate": ""
            };
            var res = await feedbackService.getAllFeedback(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setFeedbacks(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const updateTransactions = (doc, status) => {
        let bodyA = {
            "did": doc._id,
            data: {
                status: status
            }
        };
        feedbackService.updateFeedback(bodyA, token).then((res) => {
            if (res.status) {
                toast.success('Feedback updated!', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
            loadData();
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, filter]);

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    return (
        <div className="col-span-12 mt-4 px-2">
            <div className="w-full shadow-md rounded-md border">
                <table className="min-w-full divide-y divide-gray-200 px-4">
                    <thead className="bg-gray-50">
                        <tr className=''>
                            <th scope="col" className="border-b border-gray-200" colSpan={9}>
                                <div className='w-full grid grid-cols-12 gap-2'>
                                    <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                        <div className='w-full flex col-span-12 items-center'>
                                            <div className='w-[80px]'>
                                                <p>Search :</p>
                                            </div>
                                            <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by name, email, phone and message" className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                        </div>
                                    </form>
                                    <div className='col-span-1 flex items-center'>
                                        <DropdownMenu>
                                            <DropdownMenuTrigger>
                                                <button className="w-full py-1 px-2 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent>
                                                <div
                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-64px' }}>
                                                    <div>
                                                        <ul className='flex flex-col items-start p-2'>
                                                            <DropdownMenuItem>
                                                                <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                    exportToExcel();
                                                                }}>
                                                                    <div className='flex items-center text-base'>
                                                                        <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                    </div>
                                                                </li>
                                                            </DropdownMenuItem>
                                                            <DropdownMenuItem>
                                                                <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                    exportToCsv();
                                                                }}>
                                                                    <div className='flex items-center text-base'>
                                                                        <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                    </div>
                                                                </li>
                                                            </DropdownMenuItem>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Sr No
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Email
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Phone number
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Subject
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Message
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Status
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Created At
                            </th>
                            <th scope="col" className="px-2 py-3"></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {
                            feedbacks.length !== 0 && feedbacks.map((doc, index) => {
                                return <tr className="hover:bg-gray-100" key={doc._id}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-bold text-gray-900">
                                            <div>{index + 1}</div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-bold text-gray-900">
                                            <div>{doc.fname}{doc.mname == "NA" ? "" : ` ${doc.mname}`}{doc.lname == "NA" ? "" : ` ${doc.lname}`}</div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-bold text-gray-900">
                                            <div>{doc.email}</div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-bold text-gray-900">
                                            <div>+91 {doc.phone}</div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-bold text-gray-900">
                                            <div>{doc.subject}</div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-bold text-gray-900">
                                            <div>{doc.message}</div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getStsCss(doc.status)}`}>
                                            {getStsTitle(doc.status)}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-bold text-gray-900">
                                            <div>{Moment(doc.createdAt).format('DD, MMM yyyy - hh:mm:ss')}</div>
                                        </div>
                                    </td>
                                    <td>
                                        {
                                            doc.status == 'active' && <DropdownMenu>
                                                <DropdownMenuTrigger>
                                                    <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent>
                                                    <div className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-60px' }}>
                                                        <div>
                                                            <ul className='flex flex-col items-start p-2'>
                                                                <DropdownMenuItem>
                                                                    <li className='w-40 cursor-pointer rounded-md' onClick={() => {
                                                                        updateTransactions(doc, 'closed');
                                                                    }}>
                                                                        <div className='flex items-center text-base'>
                                                                            <i className="las la-chevron-circle-up text-lg"></i>
                                                                            <p className='text-sm font-serif ml-2'>Make Closed</p>
                                                                        </div>
                                                                    </li>
                                                                </DropdownMenuItem>
                                                                <DropdownMenuItem>
                                                                    <li className='w-40 cursor-pointer rounded-md' onClick={() => {
                                                                        updateTransactions(doc, 'cancel');
                                                                    }}>
                                                                        <div className='flex items-center text-base'>
                                                                            <i className="las la-ban text-lg"></i>
                                                                            <p className='text-sm font-serif ml-2'>Make Canceled</p>
                                                                        </div>
                                                                    </li>
                                                                </DropdownMenuItem>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </DropdownMenuContent>
                                            </DropdownMenu>
                                        }
                                    </td>
                                </tr>
                            })
                        }
                        {
                            feedbacks.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                <td className="px-6 py-4 whitespace-nowrap" colSpan="9">
                                    <div className="text-sm text-red-600 text-center">
                                        No data found.
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table >
                <div className='flex py-2 px-4 items-center bg-blue-50'>
                    <p className='text-sm font-serif'>Show rows per page</p>
                    <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                    </select>
                    <div className='flex-grow'></div>
                    <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                    <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                    <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                </div>
            </div>
        </div>
    )
}
