import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceProfiles {
    lookupService = new APIServiceLookUp();

    async getAllCustomer(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}customer/getAllCustomer`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getCustomerById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}customer/getCutomerById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateCustomer(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}customer/updateCustomerById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async userUpdate(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}customer/updateCustomer`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllInterpreter(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}interpreter/getAllInterpreter`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getTopInterpreter(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}interpreter/getTopInterpreter`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateInterpreter(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}interpreter/updateInterpreter`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async resetInterpreter(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}interpreter/resetInterpreter`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getInterpreterById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}interpreter/getInterpreterById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllPartner(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}partner/getAllPartner`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updatePartner(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}partner/updatePartner`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async resetPartner(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}partner/resetPartner`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getPartnerById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}partner/getPartnerById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getTopCustomers(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}customer/getTopCustomers`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}