import React, { useState, useEffect } from 'react';

import { ScrollArea } from "@/components/ui/scroll-area";

import { motion } from "framer-motion";
import { toast } from 'react-toastify';
import { Dialog } from '@headlessui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import moment from 'moment';
import axios from 'axios';
import { Edit, Trash } from 'lucide-react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from 'core/config/atoms';

import TopBar from 'core/widgets/ui/TopBar';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import icVideo from '../../assets/image/ic_video.png';
import icSrt from '../../assets/image/ic_srt.png';

import { API_URL } from 'services/models/AppConstent';

import ServiceHelp from 'services/serviceHelp';
import ServiceUtility from 'services/serviceUtility';

export default function PanelMarHelp(props) {
    const [token] = useRecoilState(tokenAtom);

    const [data, setData] = useState([]);
    const [editItem, setEditItem] = useState({});

    const [thubFile, setThubFile] = useState(null);
    const [vidFile, setVidFile] = useState(null);
    const [srtFile, setSrtFile] = useState(null);

    const [editId, setEditId] = useState('');

    const [isOpenMain, setIsOpenMain] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const [isThub, setIsThub] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const [isSrt, setIsSrt] = useState(false);

    const [progress, setProgress] = useState(0);

    const helpService = new ServiceHelp();
    const utilService = new ServiceUtility();

    const formVSchema = Yup.object().shape({
        path: Yup.string().required('This information is required'),
        name: Yup.string().required('This information is required'),
        disc: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            path: '',
            name: '',
            disc: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            if (isOpenMain && !isUpdate) {
                setSubmit(true);
                if (thubFile === null || vidFile === null || srtFile === null) {
                    toast.error('Please select a file to continue!', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    return;
                }
                var formData = new FormData();
                formData.append("path", 'videos');
                formData.append("doc", vidFile);
                fnSubmit(formData, token);
            }

            if (isOpenMain && isUpdate) {
                setSubmit(true);
                (async function () {
                    let thumFile = editItem.thumFile;
                    if (thubFile !== null) {
                        var formDataA = new FormData();
                        formDataA.append("path", editItem.thumFile.fileurl);
                        formDataA.append("doc", thubFile);

                        var resA = await utilService.replaceFile(formDataA, token);
                        thumFile = resA.data;
                    }

                    let videoFile = editItem.videoFile;
                    if (vidFile !== null) {
                        var formDataB = new FormData();
                        formDataB.append("path", editItem.videoFile.fileurl);
                        formDataB.append("doc", vidFile);

                        var resB = await utilService.replaceFile(formDataB, token);
                        videoFile = resB.data;
                    }

                    let srtData = editItem.srtFile;
                    if (srtFile !== null) {
                        var formDataC = new FormData();
                        formDataC.append("path", editItem.srtFile.fileurl);
                        formDataC.append("doc", srtFile);

                        var resC = await utilService.replaceFile(formDataC, token);
                        srtData = resC.data;
                    }
                    var body = {
                        "oid": editId,
                        "data": {
                            "path": values.path,
                            "name": values.name,
                            "disc": values.disc,
                            "srtFile": srtData,
                            "thumFile": thumFile,
                            "videoFile": videoFile,
                        }
                    };
                    var res = await helpService.updateHelp(body, token);
                    setSubmit(false);
                    if (res.status) {
                        toast.success('Help Section Updated', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        loadData();
                    } else {
                        toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                    setIsOpenMain(false);
                    setIsUpdate(false);
                    setEditId('');
                    setEditItem({});
                    handleReset();
                    setVidFile(null);
                    setSrtFile(null);
                    setThubFile(null);
                    document.getElementById("mar-form").reset();
                })();
            }
        }
    });

    const fnSubmit = (data, token) => {
        (async function () {
            setProgress(0);
            setIsVideo(true);
            var url = `${API_URL}file/uploadFile`;
            var requestHeaders = {
                'Content-type': 'multipart/form-data',
                'Accept': '*/*',
                'Authorization': `bearer ${token}`,
            };

            const responseVid = await axios.post(url, data, {
                headers: requestHeaders,
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setProgress(progress);
                },
            });
            let videoFile = responseVid.data.data;

            setProgress(0);
            setIsVideo(false);
            setIsThub(true);
            var formData = new FormData();
            formData.append("path", 'thumb');
            formData.append("doc", thubFile);

            const responseThum = await axios.post(url, formData, {
                headers: requestHeaders,
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setProgress(progress);
                },
            });
            let thumFile = responseThum.data.data;

            setProgress(0);
            setIsThub(false);
            setIsSrt(true);
            var formDataSrt = new FormData();
            formDataSrt.append("path", 'srts');
            formDataSrt.append("doc", srtFile);

            const responseSrt = await axios.post(url, formDataSrt, {
                headers: requestHeaders,
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setProgress(progress);
                },
            });
            let srtData = responseSrt.data.data;

            setProgress(0);
            setIsSrt(false);

            let body = {
                path: values.path,
                name: values.name,
                disc: values.disc,
                videoFile: videoFile,
                thumFile: thumFile,
                srtFile: srtData,
            }
            helpService.setHelp(body, token).then((res) => {
                setSubmit(false);
                if (res.status) {
                    toast.success('Help Section Updated', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    loadData();
                } else {
                    toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
                setIsOpenMain(false);
                setIsUpdate(false);
                handleReset();
                setVidFile(null);
                setSrtFile(null);
                setThubFile(null);
                document.getElementById("mar-form").reset();
            });
        })();
    }

    const editMar = (item) => {
        setEditId(item._id);
        setIsUpdate(true);
        setValues({ ...values, path: item.path, name: item.name, disc: item.disc });
        setIsOpenMain(true);
        setEditItem(item);
    }

    const delHelp = (item) => {
        (async function () {
            let bodyA = {
                path: item.thumFile.fileurl
            }
            await utilService.deleteFile(bodyA, token);
            let bodyB = {
                path: item.srtFile.fileurl
            }
            await utilService.deleteFile(bodyB, token);
            let bodyC = {
                path: item.videoFile.fileurl
            }
            await utilService.deleteFile(bodyC, token);
            let data = {
                "did": item._id
            }
            helpService.delHelp(data, token).then((res) => {
                if (res.status) {
                    toast.success('Help Section Deleted', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    loadData();
                } else {
                    toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            });
        })();
    }

    const loadData = () => {
        (async function () {
            let body = {};
            var res = await helpService.getAllHelp(body, token);
            if (res['status']) {
                setData(res.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <ScrollArea className='h-screen w-full'>
            <Dialog open={isOpenMain} onClose={() => setIsOpenMain(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Help &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpenMain(false);
                            setIsUpdate(false);
                            handleReset();
                            document.getElementById("mar-form").reset();
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handleSubmit} id="mar-form">
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Path<sup className="text-red-600">*</sup></label>
                            <input type="text" id="path" name="path" value={values.path} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. /home" onChange={handleChange} />
                            {(errors.path && touched.path) && <p className='text-xs text-red-400 mt-1'>{errors.path}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Name<sup className="text-red-600">*</sup></label>
                            <input type="text" id="name" name="name" value={values.name} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. Home help video file." onChange={handleChange} />
                            {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Description<sup className="text-red-600">*</sup></label>
                            <textarea type="text" id="disc" name="disc" value={values.disc} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full h-44 p-2.5" placeholder="i.e. Home help video file." onChange={handleChange} >
                            </textarea>
                            {(errors.disc && touched.disc) && <p className='text-xs text-red-400 mt-1'>{errors.disc}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Thumbnail File<sup className="text-red-600">*</sup></label>
                            {
                                (thubFile === null && !isUpdate) && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                <span>Upload a file</span>
                                                <input type="file" className="sr-only" required onChange={(e) => setThubFile(e.target.files[0])} />
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                            .jpg or .png up to 2mb at 16:9 Ration
                                        </p>
                                    </div>
                                </div>
                            }
                            {
                                (thubFile === null && isUpdate) && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                    <div className="w-0 flex-1 flex items-center">
                                        <img src={icVideo} alt="Icon Template" className='w-10 h-10' />
                                        <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                            {editItem.thumFile.filename}&nbsp;
                                            <i className="las la-link text-gray-400 text-lg"></i>
                                        </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                            <span>Replace</span>
                                            <input type="file" className="sr-only" onChange={(e) => setThubFile(e.target.files[0])} />
                                        </label>
                                    </div>
                                </div>
                            }
                            {
                                thubFile !== null && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                    <div className="w-0 flex-1 flex items-center">
                                        <img src={icVideo} alt="Icon Template" className='w-10 h-10' />
                                        <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                            {thubFile.name}&nbsp;
                                            <i className="las la-link text-gray-400 text-lg"></i>
                                        </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                            <span>Replace</span>
                                            <input type="file" className="sr-only" onChange={(e) => setThubFile(e.target.files[0])} />
                                        </label>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Video File<sup className="text-red-600">*</sup></label>
                            {
                                (vidFile === null && !isUpdate) && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                <span>Upload a file</span>
                                                <input type="file" className="sr-only" required onChange={(e) => setVidFile(e.target.files[0])} />
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                            .mp4 up to 200mb of 16:9 Ration
                                        </p>
                                    </div>
                                </div>
                            }
                            {
                                (vidFile === null && isUpdate) && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                    <div className="w-0 flex-1 flex items-center">
                                        <img src={icVideo} alt="Icon Template" className='w-10 h-10' />
                                        <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                            {editItem.videoFile.filename}&nbsp;
                                            <i className="las la-link text-gray-400 text-lg"></i>
                                        </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                            <span>Replace</span>
                                            <input type="file" className="sr-only" onChange={(e) => setVidFile(e.target.files[0])} />
                                        </label>
                                    </div>
                                </div>
                            }
                            {
                                vidFile !== null && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                    <div className="w-0 flex-1 flex items-center">
                                        <img src={icVideo} alt="Icon Template" className='w-10 h-10' />
                                        <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                            {vidFile.name}&nbsp;
                                            <i className="las la-link text-gray-400 text-lg"></i>
                                        </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                            <span>Replace</span>
                                            <input type="file" className="sr-only" onChange={(e) => setVidFile(e.target.files[0])} />
                                        </label>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">SRT File<sup className="text-red-600">*</sup></label>
                            {
                                (srtFile === null && !isUpdate) && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                <span>Upload a file</span>
                                                <input type="file" className="sr-only" required onChange={(e) => setSrtFile(e.target.files[0])} />
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                            .srt up to 2mb
                                        </p>
                                    </div>
                                </div>
                            }
                            {
                                (srtFile === null && isUpdate) && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                    <div className="w-0 flex-1 flex items-center">
                                        <img src={icSrt} alt="Icon Template" className='w-10 h-10' />
                                        <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                            {editItem.srtFile.filename}&nbsp;
                                            <i className="las la-link text-gray-400 text-lg"></i>
                                        </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                            <span>Replace</span>
                                            <input type="file" className="sr-only" onChange={(e) => setSrtFile(e.target.files[0])} />
                                        </label>
                                    </div>
                                </div>
                            }
                            {
                                srtFile !== null && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                    <div className="w-0 flex-1 flex items-center">
                                        <img src={icSrt} alt="Icon Template" className='w-10 h-10' />
                                        <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                            {srtFile.name}&nbsp;
                                            <i className="las la-link text-gray-400 text-lg"></i>
                                        </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                            <span>Replace</span>
                                            <input type="file" className="sr-only" onChange={(e) => setSrtFile(e.target.files[0])} />
                                        </label>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            (submit && isVideo) && <div className='my-3'>
                                <p className='text-xs text-gray-400'>Uploading video....</p>
                                <div className='w-full bg-gray-300 mt-1' style={{ height: '4px' }}>
                                    <div style={{ width: `${progress}%`, transitionDuration: `4s` }} className="h-full transition-all bg-green-400"></div>
                                </div>
                            </div>
                        }
                        {
                            (submit && isSrt) && <div className='my-3'>
                                <p className='text-xs text-gray-400'>Uploading .srt....</p>
                                <div className='w-full bg-gray-300 mt-1' style={{ height: '4px' }}>
                                    <div style={{ width: `${progress}%`, transitionDuration: `4s` }} className="h-full transition-all bg-green-400"></div>
                                </div>
                            </div>
                        }
                        {
                            (submit && isThub) && <div className='my-3'>
                                <p className='text-xs text-gray-400'>Uploading thumbnail....</p>
                                <div className='w-full bg-gray-300 mt-1' style={{ height: '4px' }}>
                                    <div style={{ width: `${progress}%`, transitionDuration: `4s` }} className="h-full transition-all bg-green-400"></div>
                                </div>
                            </div>
                        }
                        <div className='flex items-end'>
                            <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && !isUpdate && "ADD"}
                                {!submit && isUpdate && "UPDATE"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            <div className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                            <h2 class="text-2xl font-medium font-serif truncate ml-2 text-ascent">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Help Master
                            </h2>
                            <div className='flex'>
                                <div>
                                    <button className="h-10 py-1 px-3 rounded-md bg-prime hover:bg-primeLight text-white mr-4 text-sm" onClick={() => {
                                        setIsOpenMain(true);
                                    }}><i class="las la-plus mr-1"></i> ADD HELP</button>
                                </div>
                                <TopBar />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 mt-4 px-2">
                    <div className="w-full shadow-md rounded-md border">
                        <table className="min-w-full divide-y divide-gray-200 px-4">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        File
                                    </th>
                                    <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Details
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    data.length !== 0 && data.map((item, index) => {
                                        return <tr className="hover:bg-gray-100" key={index}>
                                            <td className="px-4 py-2 w-[480px]">
                                                <div className="text-sm font-bold text-gray-900">
                                                    <img src={item.thumFile.fileurl} alt="banner" className='object-cover rounded' />
                                                </div>
                                            </td>
                                            <td className="px-4 py-2 w-[800px] h-full">
                                                <div className="text-sm text-gray-900 space-y-1 flex flex-col">
                                                    <h2>PATH: <span className='font-bold'>{item.path}</span></h2>
                                                    <h2>TITLE: <span className='font-bold'>{item.name}</span></h2>
                                                    <h2 className='text-prime'>Create At: {moment(item.createdAt).format('DD MMM, yyyy')}</h2>
                                                    <h2 className='text-xs font-bold'>DISC:</h2>
                                                    <p className='text-xs text-gray-800'>{item.disc}</p>
                                                    <div className='flex-grow'></div>
                                                    <div className='flex text-white'>
                                                        <div className='bg-prime p-2 mr-2 rounded cursor-pointer' onClick={() => {
                                                            editMar(item);
                                                        }}>
                                                            <Edit size={16} />
                                                        </div>
                                                        <div className='bg-red-600 p-2 rounded cursor-pointer' onClick={() => {
                                                            delHelp(item);
                                                        }}>
                                                            <Trash size={16} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                                {
                                    data.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                        <td className="px-6 py-4 whitespace-nowrap" colSpan="6">
                                            <div className="text-sm text-red-600 text-center">
                                                No data found.
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ScrollArea>
    )
}
