import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceLog {
    lookupService = new APIServiceLookUp();

    async getAllLogs(body) {
        var serviceType = "POST";
        var url = `${API_URL}log/getAllLogs`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }
}