import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from 'core/config/atoms';

import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import ServiceProfiles from 'services/serviceProfiles';

export default function ComponentServicesList() {
    const [token] = useRecoilState(tokenAtom);

    const [customers, setCustomers] = useState([]);

    const [loading, setLoading] = useState(true);

    const profileService = new ServiceProfiles();

    const loadData = () => {
        (async function () {
            var res = await profileService.getTopCustomers({}, token);
            setLoading(false);
            if (res['status']) {
                setCustomers(res.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="col-span-4 cursor-pointer">
            <div className="shadow bg-white rounded-lg p-4">
                <div className='flex items-center justify-between'>
                    <h3 className='text-lg font-sans font-medium'>Top Performing Customer</h3>
                </div>
                <hr className='my-2' />
                {loading && <Skeleton count={10} />}
                {
                    (customers.length == 0 && !loading) && <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2'>
                        <p className='text-center'>No Data Found.</p>
                    </div>
                }
                <div className='space-y-2'>
                    {
                        customers.map((customer) => {
                            return <div className='w-full flex'>
                                {
                                    customer.profileMedia !== undefined && <div className='w-14 h-14 rounded-full overflow-hidden border-4 border-white bg-white'>
                                        <img src={Object.keys(customer.profileMedia).length === 0 ? customer.gender === "Male" ? "https://storage.googleapis.com/ish-prod/ish-assets/male.png" : "https://storage.googleapis.com/ish-prod/ish-assets/female.png" : customer.profileMedia.fileurl} alt='User Image' className='w-14 h-14 rounded-full object-cover' />
                                    </div>
                                }
                                {
                                    customer.profileMedia === undefined && <div className='w-14 h-14 bg-white rounded-full flex items-center justify-center overflow-hidden border-4 border-white'>
                                        <img src={customer.gender === "Male" ? "https://storage.googleapis.com/ish-prod/ish-assets/male.png" : "https://storage.googleapis.com/ish-prod/ish-assets/female.png"} alt='User Image' className='w-14 h-14 rounded-full object-cover' />
                                    </div>
                                }
                                <div className='flex flex-col items-start justify-start ml-2'>
                                    <h1 className='text-sm font-medium text-black'>{customer.fname}{customer.mname === "NA" ? "" : ` ${customer.mname}`}{customer.lname === "NA" ? "" : ` ${customer.lname}`}</h1>
                                    <p className='text-xs font-medium text-gray-600'>Customer</p>
                                </div>
                                <div className='flex-grow'></div>
                                <div className='text-xs bg-green-200 text-green-600 h-[20px] px-2 rounded-full flex items-center justify-center'>
                                    <p>₹ {customer.amount}</p>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}
