import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AnimatePresence, motion } from "framer-motion";

import { User, UsersRound, Building2 } from 'lucide-react';

import PanelDev from '../panels/dev/PanelDev';

import PanelProfile from 'panels/profile/PanelProfile';
import PanelCustomerInfo from 'panels/profile/PanelCustomerInfo';
import PanelProfileI from 'panels/profile/PanelProfileI';
import PanelProfileIAdd from 'panels/profile/PanelProfileIAdd';
import PanelInterpreterInfo from 'panels/profile/PanelInterpreterInfo';
import PanelProfileP from 'panels/profile/PanelProfileP';
import PanelProfilePAdd from 'panels/profile/PanelProfilePAdd';
import PanelPageStyle from 'panels/profile/PanelPageStyle';
import PanelPartnerInfo from 'panels/profile/PanelPartnerInfo';

export default function LayoutProfile() {

    const [isOpen, setIsOpen] = useState(true);

    const { page } = useParams();

    const navigate = useNavigate();

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const closeMenu = () => {
        setIsOpen(false);
    }

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && <motion.div initial={{ width: 0 }} animate={{ width: '16rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                        <motion.h1 initial="closed" animate="open" exit="closed" variants={itemVariants} className="text-2xl font-medium px-2 mx-2 py-1 my-2 rounded">Profiles Menu</motion.h1>
                        <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="w-64 flex flex-col items-start">
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'profile' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('profile') }}>
                                <UsersRound size={20} className='mr-2' /> Customer
                            </li>
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'profileI' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('profileI') }}>
                                <User size={20} className='mr-2' /> Interpreter
                            </li>
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'profileP' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('profileP') }}>
                                <Building2 size={20} className='mr-2' /> Partner
                            </li>
                        </motion.ul>
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'profile':
                                return (<PanelProfile onToggle={toggleMenu} />);
                            case 'customerInfo':
                                return (<PanelCustomerInfo onToggle={toggleMenu} />);
                            case 'profileI':
                                return (<PanelProfileI onToggle={toggleMenu} />);
                            case 'ProfileIAdd':
                                return (<PanelProfileIAdd onToggle={toggleMenu} />);
                            case 'interpreterInfo':
                                return (<PanelInterpreterInfo onToggle={toggleMenu} />);
                            case 'profileP':
                                return (<PanelProfileP onToggle={toggleMenu} />);
                            case 'ProfilePAdd':
                                return (<PanelProfilePAdd onToggle={toggleMenu} />);
                            case 'ProfilePStyle':
                                return (<PanelPageStyle onToggle={toggleMenu} />);
                            case 'partnerInfo':
                                return (<PanelPartnerInfo onToggle={toggleMenu} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
