import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceOverview {
    lookupService = new APIServiceLookUp();

    async getOrderCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}overview/getOrderCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getOrderPCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}overview/getOrderPCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getOrderDCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}overview/getOrderDCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getCustomerCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}overview/getCustomerCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getSalesCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}overview/getSalesCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getChartData(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}overview/getChartData`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}