import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { authAtom, userAtom } from '../../config/atoms';

import { LayoutDashboard, CircleUserRound, SquareUser, FileText, Inbox, Settings, LogOut } from 'lucide-react';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import icLogo from '../../../assets/image/logo-icon.png';

export default function NavigationBar() {

    const { page } = useParams();

    const [authStatus, setAuthStatus] = useRecoilState(authAtom);
    const [userData, setUserData] = useRecoilState(userAtom);

    const navigate = useNavigate();

    const changeMainMenu = (link) => {
        navigate(`/home/${link}`);
    }

    const getProfile = () => {
        switch (page) {
            case 'profile':
            case 'customerInfo':
            case 'profileI':
            case 'interpreterInfo':
            case 'ProfileIAdd':
            case 'profileP':
            case 'ProfilePAdd':
            case 'ProfilePStyle':
            case 'partnerInfo':
                return true;
            default:
                return false;
        }
    }

    const getStaff = () => {
        switch (page) {
            case 'staff':
            case 'staffAdd':
                return true;
            default:
                return false;
        }
    }

    const getSettings = () => {
        switch (page) {
            case 'setting':
            case 'company':
            case 'marLang':
            case 'marCountry':
            case 'marPackage':
            case 'marDisbilitie':
            case 'marReview':
            case 'marHelp':
                return true;
            default:
                return false;
        }
    }

    const logout = () => {
        setAuthStatus('default');
        setUserData({});
        navigate('/');
    }

    return (
        <>
            {authStatus === 'default' && <Navigate to="/" />}
            <div className="h-screen shadow-md bg-prime hidden xs:block">
                <span className="cursor-pointer" onClick={() => { changeMainMenu('main') }}>
                    <div className="w-16 h-16">
                        <img src={icLogo} className="w-12 m-auto py-2 brdImg" />
                    </div>
                </span>
                <div className='w-3/5 h-[2px] bg-white rounded-full mx-[20%] my-1'></div>
                <ul className="flex flex-col items-center h-[90%]">
                    <Tippy content="Overview Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === "main" ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('main') }}>
                            <LayoutDashboard size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Tickets Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === "ticket" ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('ticket') }}>
                            <Inbox size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Profile Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${getProfile() ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('profile') }}>
                            <CircleUserRound size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Staff Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${getStaff() ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('staff') }}>
                            <SquareUser size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Reports Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${page === "reports" ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('reports') }}>
                            <FileText size={20} />
                        </li>
                    </Tippy>
                    <div className='flex-grow'></div>
                    <Tippy content="Settings" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white hover:text-ascent ${getSettings() ? "bg-white text-ascent" : "text-white"}`} onClick={() => { changeMainMenu('setting') }}>
                            <Settings size={20} />
                        </li>
                    </Tippy>
                    <Tippy content="Logout" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl hover:bg-white text-white hover:text-ascent`} onClick={() => { logout() }}>
                            <LogOut size={20} />
                        </li>
                    </Tippy>
                </ul>
            </div>
        </>
    )
}
