import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom, paramAtom } from 'core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";

import { toast } from 'react-toastify';
import Moment from 'moment';
import Lottie from 'lottie-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Skeleton from 'react-loading-skeleton';
import ReviewStars from 'core/widgets/ui/ReviewStars';

import ServiceTicket from 'services/serviceTickets';

import animPage from 'assets/anim/anim-pageload.json';
import animBtnLoading from 'assets/anim/anim-btnLoading.json';

export default function ComponentNewOrderList() {

    const [token] = useRecoilState(tokenAtom);
    const [, setParam] = useRecoilState(paramAtom);

    const [tickets, setTickets] = useState([]);

    const [ticket, setTicket] = useState({});

    const [rtab, setRTab] = useState('a');

    const [loading, setLoading] = useState(true);
    const [selection, setSelection] = useState(false);
    const [isProcessing] = useState(true);
    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate();
    const ticketService = new ServiceTicket();

    const formVSchema = Yup.object().shape({
        feedback: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            feedback: ''
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                "tid": ticket._id,
                "feedback": values.feedback,
                "type": "admin",
            }
            ticketService.addReview(body, token).then((res) => {
                if (res.status) {
                    setSelection(true);
                    setTicket(res.data);
                } else {
                    setSelection(false);
                    toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
                setSubmit(false);
                handleReset();
            });
        }
    })

    const setSelTicket = (ticket) => {
        if (ticket.status === "live") {
            setParam({ 'eid': ticket._id });
            navigate(`/home/ticketLive`);
        } else {
            setSelection(true);
            setTicket(ticket);
        }
    }

    const getTicketTitle = (tc) => {
        if (tc.type === "partner") {
            return `(${tc.partnerDetails.subscription.links})`;
        } else {
            return "";
        }
    }

    const getTypeDept = (type) => {
        switch (type) {
            case 'on-demand':
                return "Face-To-Face";
            case 'on-partner':
                return "Tele-Interpreting";
            case 'helpdesk':
                return "Helpdesk";
            default:
                return "NA";
        }
    }

    const getTimeDiff = (date1, date2) => {
        const diffInMilliseconds = Math.abs(new Date(date2) - new Date(date1));
        const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }

    const getStsCss = (status) => {
        switch (status) {
            case 'open':
                return " bg-blue-100 text-blue-800";
            case 'live':
                return " bg-yellow-100 text-yellow-800";
            case 'closed':
                return " bg-green-100 text-green-800";
            case 'hang':
                return " bg-red-100 text-red-800";
            default:
                return " bg-gray-100 text-gray-800";
        }
    }

    const getType = (type) => {
        switch (type) {
            case 'partner':
                return 'PA';
            case 'on-demand':
            case 'on-partner':
            case 'helpdesk':
                return 'CU';
            default:
                return "NA";
        }
    }

    const getChipColorA = (status) => {
        switch (status) {
            case 'open':
                return " bg-blue-100 text-blue-800";
            case 'live':
                return " bg-yellow-100 text-yellow-800";
            case 'closed':
                return " bg-green-100 text-green-800";
            case 'hang':
                return " bg-red-100 text-red-800";
            default:
                return " bg-gray-100 text-gray-800";
        }
    }

    const gotoLink = (link) => {
        window.open(`https://partner.ishcallsign.com/${link}`, '_blank', 'noopener,noreferrer');
    }

    const getCustomerName = (data) => {
        var name = "";
        data.map((att) => {
            if (att.type === "customer") {
                name = att.name;
            }
        });
        return name;
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": {},
                "page": 1,
                "size": 10,
                "searchQuery": "",
                "startDate": "",
                "endDate": ""
            };
            var res = await ticketService.getAllTicket(body, token);
            setLoading(false);
            if (res['status']) {
                setTickets(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            {
                selection && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] min-h-screen absolute right-0 p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                {
                                    isProcessing && <>
                                        <div className='flex items-center justify-between text-2xl'>
                                            <h1 className='font-sans font-medium text-gray-900 uppercase'>Ticket #{ticket.ticketId}</h1>
                                            <span onClick={() => {
                                                setSelection(false);
                                                setTicket({});
                                                setRTab('a');
                                                loadData();
                                                handleReset();
                                            }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                        </div>
                                        <div className='flex mt-4'>
                                            <p className='font-sans font-medium'>Status:
                                                <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(ticket.status)}`}>
                                                    {ticket.status}
                                                </span>
                                            </p>
                                            <p className='ml-8 font-sans font-medium'>Language:
                                                <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase bg-blue-100 text-blue-800`}>
                                                    {ticket.ticketLang}
                                                </span>
                                            </p>
                                        </div>
                                        <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                            <span className='flex items-center'>
                                                <i className="las la-calendar mr-2 text-lg"></i>Ticket Summery
                                            </span>
                                        </div>
                                        <div className='mt-4 mr-8'>
                                            <h3 className='text-sm font-medium'>Ticket Description</h3>
                                            <p className='text-xs font-medium text-gray-400'>{ticket.ticketMessage}</p>
                                        </div>
                                        <div className='w-full h-[1px] bg-gray-900 mt-4'></div>
                                        <div className='mt-4 flex justify-between mr-8'>
                                            <span className='text-sm'>Ticket Type</span>
                                            <span className='text-sm font-medium'>{getType(ticket.type)}</span>
                                        </div>
                                        <div className='mt-2 flex justify-between mr-8'>
                                            <span className='text-sm'>Ticket Priority</span>
                                            <span className='text-sm font-medium'>{ticket.ticketPriority}</span>
                                        </div>
                                        <div className='mt-2 flex justify-between mr-8'>
                                            <span className='text-sm'>Ticket Created Time</span>
                                            <span className='text-sm font-medium'>{Moment(ticket.waitTime).format('DD, MMM yyyy - hh:mm:ss')}</span>
                                        </div>
                                        <div className='mt-2 flex justify-between mr-8'>
                                            <span className='text-sm'>Pick-up Time</span>
                                            <span className='text-sm font-medium'>{(ticket.status === 'open' || ticket.status === 'hang') ? "NA" : Moment(ticket.CallTime).format('DD, MMM yyyy - hh:mm:ss')}</span>
                                        </div>
                                        <div className='mt-2 flex justify-between mr-8'>
                                            <span className='text-sm'>Hang-Down Time</span>
                                            <span className='text-sm font-medium'>{(ticket.status === 'open' || ticket.status === 'live') ? "NA" : Moment(ticket.EndTime).format('DD, MMM yyyy - hh:mm:ss')}</span>
                                        </div>
                                        <div className='mt-2 flex justify-between mr-8'>
                                            <span className='text-sm'>Waiting Time</span>
                                            <span className='text-sm font-medium'>{ticket.status === 'open' ? "NA" : ticket.status === 'hang' ? getTimeDiff(ticket.waitTime, ticket.EndTime) : getTimeDiff(ticket.waitTime, ticket.CallTime)}</span>
                                        </div>
                                        <div className='mt-2 flex justify-between mr-8'>
                                            <span className='text-sm'>Total Talktime</span>
                                            <span className='text-sm font-medium'>{(ticket.status === 'open' || ticket.status === 'hang' || ticket.status === 'live') ? "00:00:00" : getTimeDiff(ticket.CallTime, ticket.EndTime)}</span>
                                        </div>
                                        <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                            <span className='flex items-center'>
                                                <i className="las la-user-tie mr-2 text-lg"></i>Attendees Details
                                            </span>
                                        </div>
                                        {
                                            ticket.attendeesData.map((att) => {
                                                return <div className='mt-4 mr-8'>
                                                    <h3 className='text-sm font-medium uppercase'>{att.type}</h3>
                                                    <p className='text-xs font-medium text-gray-400'>{att.name}</p>
                                                </div>
                                            })
                                        }
                                        {
                                            ticket.type === "partner" && <>
                                                <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                                    <span className='flex items-center'>
                                                        <i className="las la-building mr-2 text-lg"></i>Partner Details
                                                    </span>
                                                </div>
                                                <div className='mt-4 mr-8'>
                                                    <h3 className='text-sm font-medium uppercase'>Company Name</h3>
                                                    <p className='text-xs font-medium text-gray-400'>{ticket.partnerDetails.companyName}</p>
                                                </div>
                                                <div className='mt-4 mr-8'>
                                                    <h3 className='text-sm font-medium uppercase'>Company Address</h3>
                                                    <p className='text-xs font-medium text-gray-400'>{ticket.partnerDetails.companyAddr.address}, {ticket.partnerDetails.companyAddr.city}, {ticket.partnerDetails.companyAddr.state}, {ticket.partnerDetails.companyAddr.country} - {ticket.partnerDetails.companyAddr.zipcode}</p>
                                                </div>
                                                <div className='mt-4 mr-8'>
                                                    <h3 className='text-sm font-medium uppercase'>Company Department</h3>
                                                    <p className='text-xs font-medium text-gray-400'>{ticket.dept.title}</p>
                                                </div>
                                                <div className='mt-4 mr-8'>
                                                    <h3 className='text-sm font-medium uppercase'>Partner Link</h3>
                                                    <p className='text-xs font-medium text-blue-400 cursor-pointer' onClick={() => { gotoLink(ticket.partnerDetails.subscription.links) }}>{ticket.partnerDetails.subscription.links}</p>
                                                </div>
                                            </>
                                        }
                                        <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                            <span className='flex items-center'>
                                                <i className="las la-star-half-alt mr-2 text-lg"></i>Reviews
                                            </span>
                                        </div>
                                        <div class="w-full mt-2">
                                            <div className="py-2 mt-2">
                                                <ul className="flex text-sm font-sans text-gray-600 border-b">
                                                    <li className="mx-2"><button className={`py-2 px-2 ${rtab === 'a' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setRTab('a') }}>Customer</button></li>
                                                    <li className="mx-2"><button className={`py-2 px-2 ${rtab === 'b' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setRTab('b') }}>Interpreter</button></li>
                                                    <li className="mx-2"><button className={`py-2 px-2 ${rtab === 'c' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setRTab('c') }}>Partner</button></li>
                                                    <li className="mx-2"><button className={`py-2 px-2 ${rtab === 'd' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setRTab('d') }}>Admin</button></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {
                                            (() => {
                                                switch (rtab) {
                                                    case 'a':
                                                        return <>
                                                            {
                                                                ticket.customerRating ? <div className='my-2 px-2'>
                                                                    <h3 className='text-base font-medium'>{ticket.customerRating.name}</h3>
                                                                    <ReviewStars rating={ticket.customerRating.rating} />
                                                                    <p className='text-sm italic text-gray-600 mt-2'>{ticket.customerRating.message.length !== 0 ? ticket.customerRating.message : "NA"}</p>
                                                                </div> : <div className='my-2 px-2 h-[100px] flex items-center justify-center'>
                                                                    <p className='text-prime font-medium text-sm'>No review found.</p>
                                                                </div>
                                                            }
                                                        </>
                                                    case 'b':
                                                        return <>
                                                            {
                                                                ticket.interpreterRating ? <div className='my-2 px-2'>
                                                                    <h3 className='text-base font-medium'>{ticket.interpreterRating.name}</h3>
                                                                    <ReviewStars rating={ticket.interpreterRating.rating} />
                                                                    <p className='text-sm italic text-gray-600 mt-2'>{ticket.interpreterRating.message.length !== 0 ? ticket.interpreterRating.message : "NA"}</p>
                                                                </div> : <div className='my-2 px-2 h-[100px] flex items-center justify-center'>
                                                                    <p className='text-prime font-medium text-sm'>No review found.</p>
                                                                </div>
                                                            }
                                                        </>
                                                    case 'c':
                                                        return <>
                                                            {
                                                                ticket.partnerRating ? <div className='my-2 px-2'>
                                                                    <h3 className='text-base font-medium'>{ticket.partnerRating.name}</h3>
                                                                    <p className='text-sm italic text-gray-600 mt-1'>{ticket.partnerRating.message.length !== 0 ? ticket.partnerRating.message : "NA"}</p>
                                                                </div> : <div className='my-2 px-2 h-[100px] flex items-center justify-center'>
                                                                    <p className='text-prime font-medium text-sm'>No review found.</p>
                                                                </div>
                                                            }
                                                        </>
                                                    case 'd':
                                                        return <>
                                                            {
                                                                ticket.adminRating ? <div className='my-2 px-2'>
                                                                    <h3 className='text-base font-medium'>Admin</h3>
                                                                    <p className='text-sm italic text-gray-600 mt-2'>{ticket.adminRating.message.length !== 0 ? ticket.adminRating.message : "NA"}</p>
                                                                </div> : <div className='my-2 px-2 h-[100px] flex items-center justify-center'>
                                                                    <Dialog>
                                                                        <DialogTrigger asChild>
                                                                            <button className='bg-blue-200 text-prime font-medium text-sm px-4 py-1 rounded-md hover:bg-blue-300 hover:text-white'>Add Review</button>
                                                                        </DialogTrigger>
                                                                        <DialogContent className="sm:max-w-md">
                                                                            <DialogHeader>
                                                                                <DialogTitle>Add Feedback</DialogTitle>
                                                                            </DialogHeader>
                                                                            <hr />
                                                                            <form onSubmit={handleSubmit}>
                                                                                <div className="w-full">
                                                                                    <div className="">
                                                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Feedback<sup className="text-red-600">*</sup></label>
                                                                                        <textarea id="feedback" value={values.feedback} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-20 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. Your feedback"></textarea>
                                                                                        {(errors.feedback && touched.feedback) && <p className='text-xs text-red-400 mt-1'>{errors.feedback}</p>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="px-2 py-3 text-right">
                                                                                    <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                                                                                        {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                                                                        {!submit && "SUBMIT"}
                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                        </DialogContent>
                                                                    </Dialog>
                                                                </div>
                                                            }
                                                        </>
                                                    default:
                                                        return <div className='my-2 px-2 h-[100px] flex items-center justify-center'>
                                                            <p className='text-prime font-medium text-sm'>No review found.</p>
                                                        </div>
                                                }
                                            })()
                                        }
                                    </>
                                }
                                {
                                    !isProcessing && <>
                                        <div className='flex items-center justify-center w-full h-full'>
                                            <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                                        </div>
                                    </>
                                }
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            }
            <div className="col-span-12 cursor-pointer">
                <div className="shadow bg-white rounded-lg p-4">
                    <h3 className='text-lg font-sans font-medium'>New Tickets | Top 10</h3>
                    <hr className='my-2' />
                    {loading && <Skeleton count={10} />}
                    <table className="min-w-full divide-y divide-gray-200 px-4">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    TICKET DETAILS
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Ticket Type
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Customer Name
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Department
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Priority Type
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Ticket Created Time
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Waiting Time
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Pick-up Time
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Hang-Down Time
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Total Talktime
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Ratings
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {
                                tickets.length !== 0 && tickets.map((ticket) => {
                                    return <tr className="hover:bg-gray-100" key={ticket._id}>
                                        <td className="px-6 py-4 whitespace-nowrap cursor-pointer" onClick={() => {
                                            setSelTicket(ticket);
                                        }}>
                                            <div className="text-sm font-bold text-gray-900">
                                                <div className='flex items-center text-prime uppercase'>
                                                    {ticket.ticketId} {`${getTicketTitle(ticket)}`}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-bold text-gray-900">
                                                <div>{getType(ticket.type)}</div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-bold text-gray-900">
                                                <div>{getCustomerName(ticket.attendeesData)}</div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-bold text-gray-900">
                                                {ticket.dept != null && Object.keys(ticket.dept).length !== 0 && <div>{ticket.dept.title}</div>}
                                                {ticket.dept != null && Object.keys(ticket.dept).length === 0 && <div>NA</div>}
                                                {ticket.dept == null && <div>{getTypeDept(ticket.type)}</div>}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-bold text-gray-900">
                                                <div>{ticket.ticketPriority}</div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-bold text-gray-900">
                                                <div>{Moment(ticket.waitTime).format('DD, MMM yyyy - hh:mm:ss')}</div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-bold text-gray-900">
                                                <div>{ticket.status === 'open' ? "NA" : ticket.status === 'hang' ? getTimeDiff(ticket.waitTime, ticket.EndTime) : getTimeDiff(ticket.waitTime, ticket.CallTime)}</div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-bold text-gray-900">
                                                <div>{(ticket.status === 'open' || ticket.status === 'hang') ? "NA" : Moment(ticket.CallTime).format('DD, MMM yyyy - hh:mm:ss')}</div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-bold text-gray-900">
                                                <div>{(ticket.status === 'open' || ticket.status === 'live') ? "NA" : Moment(ticket.EndTime).format('DD, MMM yyyy - hh:mm:ss')}</div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-bold text-gray-900">
                                                <div>{(ticket.status === 'open' || ticket.status === 'hang' || ticket.status === 'live') ? "00:00:00" : getTimeDiff(ticket.CallTime, ticket.EndTime)}</div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {ticket.customerRating && <ReviewStars rating={ticket.customerRating.rating} />}
                                            {!ticket.customerRating && <ReviewStars rating={0} />}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getStsCss(ticket.status)}`}>
                                                {ticket.status.toUpperCase()}
                                            </span>
                                        </td>
                                    </tr>
                                })
                            }
                            {
                                tickets.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                    <td className="px-6 py-4 whitespace-nowrap" colSpan="12">
                                        <div className="text-sm text-blue-600 text-center">
                                            No data found.
                                        </div>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
